import React from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import '../main.css'
import profileImg from '../assets/images/profile.png'

function Home() {
  return (
    <>
      <div class="grid">
        <div class="max-wrapper">
          <header>
            <Nav />
          </header>
          <AnimatePresence>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{duration: 0.6}}
            >
              <section class="intro">
                <h1 class="introTxt">
                  Hey! This is Juan, a digital designer currently working on design systems at{' '}
                  <a
                    class="out"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://newrelic.com"
                  >
                    New Relic
                  </a>
                </h1>
              </section>
            </motion.div>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{delay: 0.15, duration: 0.6}}
              class="profileImg"
            >
              <img
                class="homeImg"
                src={profileImg}
                alt="Juan smiling in front of a graffiti-covered wall, black and white photograph."
              />
            </motion.div>
            <Footer />
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default Home
